.backdrop() {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--default-bg);
}

.email-info-panel {
  border-radius: 10px;
  align-items: center;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--default-bg);
  max-width: 992px;
  margin: auto;
  padding: 25px 20px 40px 20px;
  display: block;

  &.card-created-account-confirmed {
    padding: 45px 20px 50px 20px !important;

    .sub-title {
      padding: 0 !important;
    }
  }

  &.card-created-email {
    padding: 40px 20px 60px 20px !important;
  }

  .card-content {
    display: flex;
    flex-direction: column;

    div {
      text-align: center;
    }
  }

  .not-validate {
    color: var(--content-invalid) !important;

    i[data-fa-i2svg] {
      color: var(--content-invalid) !important;
    }
  }

  .icon {
    text-align: center;
    animation-name: scale;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);

    i[data-fa-i2svg] {
      margin: auto;
      color: var(--valid);
      font-size: 42px;
      font-weight: lighter;
    }
  }

  @keyframes scale {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  .title {
    font-size: 34px;
    color: var(--primary-text-1);
    width: 100%;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: left;
  }

  .sub-title {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 25px;
    text-align: left;
    color: var(--primary-text-1);
  }

  .text {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
    text-align: left;
    color: var(--primary-text-1);
  }

  a {
    width: 40%;
    margin-top: 60px;
    margin-left: 30%;

    &.nano-button {
      margin: 0 auto;
    }
  }

  @media (max-width: 480px) {
    a {
      margin: 20px 0 0;
      width: 100%;
    }
  }
}

.resend-email-confirmation {
  max-width: 992px;
  display: flex;
  justify-content: space-evenly;
  margin: 25px auto;

  .nano-button-submit {
    width: auto;
  }

  .inner {
    .backdrop();
    text-align: center;
    padding: 50px;
    color: var(--primary-text-1) !important;

    .no-email-received {
      font-size: 24px !important;
      font-weight: 700;
    }

    .no-email-resend {
      font-size: 18px !important;
      font-weight: 700;
    }

    .no-email-disclaimer {
      font-size: 15px !important;
      font-weight: 400;
      padding: 15px 0;
    }

    hr {
      width: 50%;
    }

    .resend-email-input {
      div:has(.action-button) {
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .action-button {
          white-space: nowrap;
          margin: 10px;
        }
      }

      #validate_email_form {
        div {
          button {
            margin: 40px auto 0;
          }
        }
      }

      .mail-sent-title {
        font-size: 22px;
        margin: 10px;
        font-weight: 700;
      }

      .mail-sent-subtitle {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
      }

      label,
      input {
        display: none;
      }

      ul {
        list-style-type: none;
        color: var(--content-invalid);
        padding: 10px 0 0 0 !important;
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
