.family-access-popup {
  background-color: var(--default-bg);

  .family-access-popup-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    aspect-ratio: 740 / 170;
    width: 100%;
    height: auto;
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-popup-header-wave.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    z-index: 10;

    .close-container {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--default-bg);
      left: unset;
      right: 10px;
      top: 10px;

      i,
      svg {
        font-size: 30px !important;
        color: var(--troubadour-main-5);
      }
    }
  }

  .family-access-popup-content {
    display: flex;
    flex-flow: column wrap;
    padding: 40px 10% 40px;

    h2 {
      color: var(--troubadour-main);
      font-family: Eagle, Lato, sans-serif;
      font-size: clamp(24px, 3vw, 32px);
      font-weight: 400;
      line-height: 1.2em;
      letter-spacing: 0.96px;
      text-transform: none;
      margin: 0 0 20px;
    }

    p {
      color: var(--troubadour-main-5);
      font-size: clamp(14px, 2vw, 18px);
      line-height: 1.2em;
      margin: 0 0 20px;
    }

    .access-code-container {
      .number-code-container {
        label {
          color: var(--troubadour-main-5);
        }

        .fields {
          input {
            border: 1px solid var(--troubadour-main-5);

            &:focus,
            &:focus-visible {
              border-width: 2px;
            }
          }
        }
      }
      .switch-member-error {
        text-align: center;
        color: var(--troubadour-main);
        font-size: clamp(12px, 2vw, 14px);
      }
    }

    .forgot-passcode {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      color: var(--troubadour-main-5);
      text-align: center;
      margin: 0 0 30px;

      .forgot-passcode-warning {
        color: var(--troubadour-main);
      }

      a {
        color: var(--troubadour-main-5);
        text-decoration: underline;
      }
    }

    .action-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column wrap;
      gap: 20px;

      a:not(.troubadour-button) {
        color: var(--troubadour-main-5);
        font-size: clamp(14px, 2vw, 18px);
        line-height: 1.2em;
        font-weight: 800;
        text-decoration: underline;
        margin: 0 0 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// nanopopup overrides
@media (min-width: 1px),
  (max-width: 1280px),
  (max-width: 992px),
  (max-width: 767px),
  (max-width: 480px) {
  .nanopopup {
    &.family-access-popup {
      padding: 0 !important;
      width: 90vw !important;
      height: auto !important;
      min-width: 300px !important;
      max-width: 640px !important;
    }
  }
}
