.contact-footer {
  text-align: left;
  margin-top: auto;
  background-color: var(--admin-main-darker);
  display: flex;
  flex: 0;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 20px;

  .contact-footer-content {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .address-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-right: 20px;

    .name {
      margin-bottom: 0;
    }

    .address {
      margin-top: 0;
    }

    .address,
    .name a {
      font-size: 15px;
      line-height: 19px;
      font-weight: 400;
      color: var(--default-color-2) !important;
    }
  }

  .img-container {
    width: 100%;
    max-width: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div {
      width: 100%;
      aspect-ratio: 259 / 196;
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-logo-light.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media screen and (min-width: 800px) {
    padding: 20px 40px;

    .address-container {
      .address,
      .name a {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .img-container {
      max-width: 100px;
    }

    .copyright-container {
      margin-top: 20px;

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 30px 80px;

    .address-container {
      .address,
      .name a {
        font-size: 30px;
        line-height: 35px;
      }
    }

    .img-container {
      max-width: 185px;
    }

    .copyright-container {
      margin-top: 40px;

      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 800;
      }
    }
  }

  // Full footer: Theme overrides for CdO pages
  &.constellation {
    background-color: var(--constellation-main-4);

    .img-container {
      div {
        aspect-ratio: 190 / 210;
        background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-logo-white-trans.svg");
      }
    }
  }

  // Full footer: Theme overrides for Troub pages
  &.troubadour {
    background-color: var(--troubadour-main-8);

    .img-container {
      div {
        aspect-ratio: 190 / 210;
        background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-logo-red.svg");
      }
    }
  }

  // Full footer: Theme overrides for Black theme
  &.black {
    background-color: var(--default-color-3);

    .img-container {
      div {
        aspect-ratio: 190 / 210;
        background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-logo-white.svg");
      }
    }
  }

  // Full footer: Theme overrides for Teal theme
  &.teal {
    background-color: var(--tutorial-color-9);

    .img-container {
      div {
        aspect-ratio: 190 / 210;
        background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-logo-white.svg");
      }
    }

    .copyright-container {
      a {
        color: var(--constellation-main-5);
      }
    }
  }
}

// Can be displayed inside main footer or standalone
.copyright-container {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-top: 20px;
  color: var(--default-color-2);

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 5px;
  }

  a {
    color: var(--troubadour-main-3);
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.copyright-standalone {
    position: relative;
    margin: 0;
    padding: 20px;
    z-index: 5;

    p {
      font-size: 12px;
      line-height: 1em;
      color: var(--default-color);
    }

    a {
      color: var(--constellation-main-2);
      font-weight: 800;

      &.constellation,
      &.troubadour {
        color: var(--constellation-main-2);
      }
    }
  }
}
