.nanopopup.login-user-type-default-popup {
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--landing-page-color1);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  a {
    color: var(--landing-page-heading-dark);
    &:hover {
      text-decoration: underline;
      color: var(--landing-page-color1);
    }
  }

  .admin-popup-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 110px;
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/adm-popup-header-wave.png");
    background-repeat: no-repeat;
    background-position: center -60px;
    z-index: 10;

    .close-container {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--landing-page-popup-close-bg);
      left: 10px;
      top: 10px;

      svg {
        font-size: 20px;
        color: var(--default-color-2);
      }

      &:hover {
        background-color: var(--landing-page-popup-close-bg-hover);
      }
    }

    .admin-popup-header-logo {
      display: flex;
      flex-direction: row;
      padding-left: 10%;
      margin-bottom: 10px;
      align-items: center;
      gap: 20px;

      img {
        width: 80px;
      }

      .admin-popup-header-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 800;
        color: var(--default-color-2);
      }
    }
  }

  .admin-popup-user-types {
    padding: 0 20px 40px;

    h2 {
      text-align: center;
      color: var(--landing-page-heading-light);
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    .user-types-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      gap: 20px;

      .user-type-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        max-width: 400px;
        padding: 20px 10px;
        border: 1px solid var(--landing-page-popup-checkbox-border);
        border-radius: 20px;
        text-decoration: none;
        cursor: pointer;

        &:focus {
          outline: 0;
        }

        &:hover {
          text-decoration: underline;
          border-color: var(--landing-page-heading-light);
          background-color: var(--landing-bg-3);
        }

        p {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          white-space: nowrap;
          margin: 15px 0 0;
        }

        img {
          max-width: 100%;
        }
      }

      .user-type-child-selection-warning {
        position: absolute;
        display: none;
        flex-direction: column;
        padding: 40px;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: var(--landing-bg-3);
        border: 1px solid var(--landing-page-heading-light);
        border-radius: 20px;

        .close-warning {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: -20px;
          top: -20px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--landing-page-heading-light);
          cursor: pointer;

          svg {
            color: var(--default-color-2);
          }
        }

        p:first-of-type {
          font-weight: 800;
        }
      }

      .user-type-student-child:focus ~ .user-type-child-selection-warning {
        display: flex;
      }
    }
  }

  @media screen and (min-width: 801px) {
    font-size: 20px;
    line-height: 25px;

    .admin-popup-header {
      background-position: center center;
      min-height: 160px;

      .close-container {
        width: 50px;
        height: 50px;
        left: 15px;
        top: 15px;

        svg {
          font-size: 25px;
        }
      }

      .admin-popup-header-logo {
        img {
          width: 120px;
        }

        .admin-popup-header-title {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }

    .admin-popup-user-types {
      padding: 20px 40px 40px;

      .user-types-container {
        flex-direction: row;
        align-items: stretch;

        .user-type-item {
          width: 45%;
        }
      }
    }
  }
}

// nanopopup overrides
@media (min-width: 1px),
  (max-width: 1280px),
  (max-width: 992px),
  (max-width: 767px),
  (max-width: 480px) {
  .nanopopup {
    &.login-user-type-default-popup {
      height: auto !important;
      min-height: auto !important;
      max-height: auto !important;
      min-width: 350px !important;
      max-width: 726px !important;
    }
  }
}
