.troubadour-popup {
  .close-button {
    color: var(--troubadour-main);
  }

  .title {
    font-family: FatFrank, sans-serif;
    color: var(--troubadour-main);
  }

  .main-text {
    text-align: center;
    opacity: 1;
    font-family: Sofia Pro, sans-serif;
    font-size: 24px;
    line-height: 28px;
  }

  .inputs-group {
    margin: 15px 0;
    width: 49%;
    display: inline-block;
  }

  .button-group {
    .cancel-button {
      border: none;

      &:hover {
        .button-text {
          color: var(--troubadour-main) !important;
        }
      }
    }

    .action-button {
      &:hover {
        .button-text {
          color: var(--button-action) !important;
        }
      }
    }

  }

  &.newsletter-popup {
    padding: 0 !important;
    height: 725px !important;
    min-width: 35vw !important;

    iframe {
      height: 99%;
    }
  }

  @media screen and (max-width: 1368px) {
    &.newsletter-popup {
      min-width: 50vw !important;

    }
  }
  @media screen and (max-width: 768px) {
    .inputs-group {
      width: 100%;
      display: block;
    }

    .button-group {
      button {
        margin: 15px 0;
      }
    }

    &.newsletter-popup {
      width: 90%;
      max-width: 90% !important;
      min-width: 85% !important;
      left: 5% !important;

    }
  }
  @media screen and (max-width: 480px) {

    &.newsletter-popup {
      width: 90%;
      max-width: 90% !important;
      min-width: 85vw !important;
      left: 5% !important;
    }
  }
}