.nanopopup.troub-login-popup {
  padding: 0 !important;
  overflow: hidden;
  color: var(--landing-page-color1);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  h2.title {
    color: var(--troubadour-main);
    text-align: center;
    font-family: Eagle, Lato, sans-serif;
    font-size: clamp(24px, 3vw, 32px);
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.96px;
    text-transform: none;
    margin: 0 0 30px;
  }

  h3.subtitle {
    color: var(--troubadour-main-5);
    text-align: center;
    font-size: clamp(12px, 2vw, 14px);
    font-style: normal;
    line-height: 1em;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  .family-popup-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    aspect-ratio: 740 / 170;
    width: 100%;
    height: auto;
    min-height: 100px;
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-popup-header-wave.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    z-index: 10;

    .close-container {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--default-bg);
      left: 10px;
      top: 10px;

      i,
      svg {
        font-size: 25px;
        color: var(--troubadour-main-5);
      }
    }

    .purchase {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .popup-header-troub-logo {
      width: 15%;
      max-width: 150px;
      margin: 0;
      transform: translateY(47%);

      img {
        aspect-ratio: 1 / 1;
        width: 100%;
      }
    }

    .login-redirection-message {
      width: 100%;
      padding: 0 20px 40px 3em;
      color: var(--default-color-2);
      font-size: clamp(14px, 3vw, 30px);
      line-height: 1em;
      text-align: center;
    }
  }

  .family-popup-login-form {
    padding: 30px 10%;

    .account-creation-prompt {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 20px;
      gap: 5px 20px;

      p {
        color: var(--troubadour-main);
        font-size: clamp(14px, 2vw, 18px);
        line-height: 1;
        margin: 0;
        white-space: nowrap;
      }

      a {
        color: var(--troubadour-main-5);
        font-size: clamp(16px, 2vw, 20px);
        font-weight: 800;
        line-height: 1;
        text-decoration-line: underline;
        white-space: nowrap;
        margin: 0;
        flex: 1;
      }
    }

    // nanomonx form overrride
    .form-control:not([type="checkbox"]),
    .form-control:not([type="radio"]) {
      border-color: var(--troubadour-main-5);
    }

    .invalid-credential {
      color: var(--content-invalid);
    }

    .frm-submit-button {
      width: 100%;
      text-align: center;
      margin: 0;
    }

    .form-footer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin: 0 0 30px;
      gap: 10px 20px;

      .form-field {
        margin: 0;

        label {
          font-size: clamp(14px, 2vw, 18px);
          font-weight: 700;
          white-space: wrap;
        }
      }

      a {
        color: var(--troubadour-main-5);
        font-size: clamp(14px, 2vw, 18px);
        line-height: 1;
        text-decoration-line: underline;
        margin: 0;
      }
    }
  }

  .nanomonx-form {
    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 5px;
      padding: 5px;
      min-width: 25px;
      min-height: 25px;
      max-height: 25px;
      max-width: 25px;
      border: 2px solid var(--troubadour-main-5);
      transition: 0.2s all linear;
      border-radius: 4px;
      background-color: var(--default-bg);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1px;

      &:disabled {
        border-color: var(--border) !important;
      }

      &:focus {
        outline: none;
      }

      &:checked {
        &:after {
          content: "";
          transform: rotate(45deg);
          width: 5px;
          height: 10px;
          border-bottom: 3px solid var(--troubadour-main-5);
          border-right: 2px solid var(--troubadour-main-5);
        }
      }

      &:hover {
        cursor: pointer;
      }

      // All the label that are after a check box
      & ~ label {
        color: var(--troubadour-main-5);
        font-size: 16px;
        padding: 6px;
        margin: 0;

        &:hover {
          cursor: pointer;
        }
      }

      &:disabled {
        cursor: unset !important;

        & ~ label {
          color: var(--primary-text-3);
          opacity: 0.8;
          cursor: unset !important;
        }
      }
    }
  }

  .family-popup-user-types {
    display: flex;
    flex-flow: column wrap;
    padding: 20px 10% 40px;

    .back-login-link {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 10px;
      font-size: clamp(12px, 2vw, 16px);
      color: var(--troubadour-main-5);
      font-weight: 400;
      text-decoration: none;
      gap: 10px;
    }

    .user-types-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto 20px;
      padding: 0;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      gap: 17px;

      .user-type-item {
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        background-color: var(--troubadour-main-15);
        border-radius: 20px;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        align-self: stretch;
        padding: 20px;
        overflow: hidden;
        opacity: 1;

        &:hover {
          background-color: var(--troubadour-main-7);
        }

        .type-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          align-self: stretch;

          img {
            max-width: 100%;
            max-height: 60px;
          }
        }

        .type-title {
          color: var(--troubadour-main-5);
          font-size: clamp(16px, 2vw, 24px);
          font-weight: 800;
          line-height: 1em;
          text-align: center;
          white-space: nowrap;
          margin: 0;
          padding: 5px;
          flex: 1;
        }

        .type-selection {
          position: absolute;
          top: 10px;
          right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          align-self: stretch;

          .bullet {
            width: 100%;
            aspect-ratio: 1 /1;
            max-width: 50px;
            border-radius: 50%;
            border: 2px solid var(--babillard-color-1);

            img,
            svg {
              display: none;
              width: 20px;
              height: 20px;
            }
          }
        }

        &:focus {
          outline: 0;
          background-color: var(--troubadour-main-7);

          .type-selection {
            .bullet {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: var(--troubadour-main-5);
              border: 2px solid var(--troubadour-main-5);

              img,
              svg {
                display: block;
                color: var(--default-color-2);
              }
            }
          }
        }

        @media screen and (min-width: 600px) {
          flex-flow: row nowrap;
          height: 90px;
          padding: 0;

          .type-avatar {
            width: 30%;
            align-items: flex-end;
            align-self: stretch;

            img {
              max-width: 100%;
              max-height: 80px;
            }
          }

          .type-selection {
            position: static;
            width: 100px;
            align-self: stretch;

            .bullet {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }

    .purchase-gift {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      a {
        font-size: clamp(14px, 2vw, 18px);
        color: var(--troubadour-main-5);
        text-decoration: underline;
        font-weight: 400;
      }
    }
  }

  .family-selection-message {
    p {
      color: var(--troubadour-main-5);
      text-align: center;
      font-size: clamp(14px, 2vw, 18px);
      line-height: 1.2em;
      margin: 0 0 30px;

      strong {
        font-weight: 700;
      }
    }

    .action-buttons {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      gap: 20px;

      a:not(.troubadour-button) {
        color: var(--troubadour-main-5);
        text-align: center;
        font-size: 18px;
        font-weight: 800;
        line-height: 1em;
        text-decoration-line: underline;
        opacity: 1;

        &:hover {
          color: var(--default-color-3);
        }
      }
    }
  }

  .child-selection-message {
    display: flex;
    flex-flow: column wrap;
    padding: 40px 10% 40px;
  }

  .teacher-selection-message {
    padding: 40px 10% 40px;
  }

  .platform-selection-options {
    margin: 40px 0 100px;
    height: auto;

    .product-cards-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      margin: 40px auto;
      padding: 0 40px;
      width: 100%;
      gap: 40px 20px;

      .product-card {
        width: 230px;
        height: 235px;
        align-self: stretch;

        .card-wrapper {
          position: relative;
          transform-style: preserve-3d;
          width: 100%;
          height: 100%;

          .card-inner {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            color: var(--default-color-2);
            width: 100%;
            height: 100%;
            padding: 25px;
            border-radius: 15px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            text-decoration: none;

            &.card-front {
              cursor: pointer;
              z-index: 2;
              transform: rotateY(0deg);

              img {
                width: 100%;
                max-width: 60%;
              }

              h4 {
                color: var(--default-color-2);
                text-align: center;
                font-family: "Sofia Pro";
                font-size: 14px;
                font-weight: 900;
                line-height: 1.4em;
                text-transform: none;
                margin: 0;
              }
            }
          }
        }

        .card-caption {
          color: var(--troubadour-main-5);
          text-align: center;
          font-size: clamp(12px, 2vw, 14px);
          font-weight: 400;
          line-height: 1.2em;
          margin: 0;
          padding: 8px 0;
        }

        &.product-troubadour {
          .card-inner {
            background-color: var(--troubadour-main-5);

            &.card-front {
              h4 {
                color: var(--troubadour-main-7);
                margin-top: -5px;
              }
            }
          }
        }

        &.product-family {
          .card-inner {
            background: var(--troubadour-section-bg2);

            &.card-front {
              h4 {
                margin-top: -5px;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 699px) {
      margin: 10px 0;

      .product-cards-container {
        margin: 10px auto 60px;

        .product-card {
          width: 230px;
          height: 185px;
        }
      }
    }
  }

  .family-popup-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    padding: 20px;
    background-color: var(--troubadour-main-16);
    gap: 10px;

    p {
      color: var(--troubadour-main-5);
      font-size: clamp(12px, 2vw, 16px);
      font-weight: 400;
      line-height: 1.2em;
      margin: 0;
    }
  }
}

// nanopopup overrides
@media (min-width: 1px),
(max-width: 1280px),
(max-width: 992px),
(max-width: 767px),
(max-width: 480px) {
  .nanopopup {
    &.troub-login-popup {
      height: auto !important;
      min-height: auto !important;
      max-height: 96vh !important;
      min-width: 320px !important;
      max-width: 600px !important;
    }
  }
}
