.nanomonx-form {
  .nanomonx-form-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 940px) {
      flex-direction: row;
      gap: 20px;
    }
  }

  .form-field {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    color: var(--default-color-3);
    font-size: 16px;
    flex: 1;

    .form-field-group {
      position: relative;
      display: flex;
      flex-direction: row;

      div {
        position: relative;
      }
    }

    &.form-field-select {
      background-color: var(--default-color-2);
      padding: 10px 0;
      border: 2px solid var(--default-color);
      border-radius: 10px;
    }

    &.form-field-checkbox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: row nowrap;
    }

    .existing-account-text,
    .creating-account-text {
      display: inline-block;
      font-size: 14px;
      line-height: 1.2em;
      font-weight: 600;
      margin: 10px 0;
      padding: 0;
    }

    .field-error-msg,
    .different-email-text {
      display: inline-block;
      font-size: 14px;
      line-height: 1.2em;
      font-weight: 600;
      margin: 10px 0;
      padding: 0;
    }

    label {
      font-size: 14px;
      line-height: 1.2em;
      font-weight: 400;
      margin: 0 0 10px;
    }
  }

  // intl-tel-input plugin
  .form-field-intl-tel-input {
    .iti {
      width: 100%;

      .iti__flag-container {
        width: 82px !important;

        .iti__selected-flag {
          justify-content: space-between;
          padding: 0 2px 0 10px;
        }
      }

      input {
        padding-left: 86px !important;

        &::placeholder {
          opacity: 0.5;
        }
      }
    }
  }

  .form-field-tel-ext {
    @media screen and (min-width: 940px) {
      max-width: 150px;
    }
  }

  .form-control:not([type="checkbox"]),
  .form-control:not([type="radio"]) {
    position: relative;
    padding: 1rem 2.5rem 1rem 1rem;
    height: auto;
    line-height: 1em;
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    background-clip: padding-box;
    color: var(--default-color-3);
    background-color: var(--default-color-2) !important;
    border: 2px solid var(--content-neutral);
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border-color: var(--content-warning);
      outline: none;
      box-shadow: none;
    }

    &.isValid:not(:placeholder-shown) {
      border-color: var(--content-valid);
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/admin-content-valid.svg");
      background-position: right 10px center;
      background-repeat: no-repeat;
    }

    &.isInvalid {
      border-color: var(--content-invalid);
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/admin-content-invalid.svg");
      background-position: right 10px center;
      background-repeat: no-repeat;
    }

    &.type-textarea {
      height: 100px;
    }

    &.type-select {
      width: calc(100% - 30px);
      height: calc(3.5rem + 2px);
      line-height: 1.25;
      display: block;
      border: 0;
      outline: 0;
      margin: 0 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      box-shadow: none;
    }
  }

  .form-captcha {
    font-size: 14px;
    line-height: 16px;
    margin: 15px auto;
    padding: 20px;
    max-width: 600px;
  }

  .action-buttons {
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 30px 0 0;
  }

  // intl-tel-input plugin
  .iti__arrow {
    border: none;
  }

  .iti__flag {
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/country-flags.png");
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/country-flags@2x.png");
    }
  }

  &.nanomonx-form-light {
    .existing-account-text,
    .creating-account-text {
      color: var(--default-color-3);
    }

    .field-error-msg,
    .different-email-text {
      color: var(--content-invalid);
    }

    label {
      color: var(--default-color-3);
    }

    .form-captcha {
      color: var(--default-color-3);
    }
  }

  &.nanomonx-form-dark {
    .existing-account-text,
    .creating-account-text {
      color: var(--default-color-2);
    }

    .field-error-msg,
    .different-email-text {
      color: var(--default-color-2);
    }

    label {
      color: var(--default-color-2);
    }

    .form-captcha {
      color: var(--default-color-2);
    }
  }
}
