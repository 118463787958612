.showcase-header-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 80px;
  max-height: 120px;
  padding: 0;
  overflow: hidden;
  z-index: 10;

  .not-mobile {
    display: flex;
  }

  .mobile-only {
    display: none;
  }

  .showcase-text-section {
    position: relative;
    font-size: 20px;
    padding: 0 20px;
    margin: 0;
    height: 100%;

    &::after {
      display: none;
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%) rotate(-45deg);
      width: 16px;
      height: 16px;
      background-color: var(--default-bg);
      z-index: 2;
    }
  }

  .showcase-heading-section {
    color: var(--default-color-2);
    font-family: Sofia Pro, sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 1.2em;
    justify-content: flex-end;
    text-align: right;
    padding: 25px;
  }

  .showcase-button-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Sofia Pro, sans-serif;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    z-index: 1;
    max-height: 120px;

    .button-container {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px 25px 25px 100px;
      min-width: 300px;

      .triangle-container {
        position: absolute;
        height: 100%;
        width: 100px;
        left: -2px;
        top: 0;
        overflow: hidden;
        z-index: 0;

        .triangle {
          height: 100%;
          width: 120px;
          transform: rotate(45deg);
          position: absolute;
          left: -70px;
          border-radius: 5px;
        }
      }

      .showcase-button {
        margin: 10px 0;
      }
    }
  }

  &.troubadour-showcase-header {
    background-color: var(--tbd-showcase-header-left-bg);
    color: var(--default-color-2);

    .showcase-text-section {
      &::after {
        background-color: var(--tbd-showcase-header-left-bg);
      }
    }

    .showcase-button-section {
      color: var(--tbd-showcase-header-right-bg);

      .button-container {
        background-color: var(--tbd-showcase-header-right-bg);

        .triangle {
          background: var(--tbd-showcase-header-left-bg);
        }
      }
    }
  }

  &.constellation-showcase-header {
    background-color: var(--cdo-showcase-header-left-bg);
    color: var(--default-color-2);

    .showcase-text-section {
      &::after {
        background-color: var(--cdo-showcase-header-left-bg);
      }
    }

    .showcase-button-section {
      color: var(--cdo-showcase-header-right-bg);

      .button-container {
        background-color: var(--cdo-showcase-header-right-bg);

        .triangle {
          background: var(--cdo-showcase-header-left-bg);
        }
      }
    }
  }

  &.admin-showcase-header {
    background: var(--adm-showcase-header-left-bg);
    color: var(--default-color-2);

    .showcase-text-section {
      &::after {
        background-color: var(--adm-showcase-header-left-bg);
      }
    }

    .showcase-button-section {
      color: var(--adm-showcase-header-right-bg);

      .button-container {
        background-color: var(--adm-showcase-header-right-bg);

        .triangle {
          background: var(--adm-showcase-header-left-bg);
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    .not-mobile {
      display: none !important;
    }

    .mobile-only {
      display: flex !important;
    }
  }

  // CSS rules when text section is present
  &[data-showcase-text-section="true"] {
    .showcase-text-section {
      display: block;
    }

    .showcase-heading-section {
      flex: 1 0 25%;
    }

    @media screen and (max-width: 1300px) {
      .showcase-text-section {
        font-size: 14px;
      }
      .showcase-heading-section {
        font-size: 26px;
      }
    }

    @media screen and (max-width: 950px) {
      .showcase-text-section {
        font-size: 13px;
      }

      .showcase-heading-section {
        padding: 0 15px;
      }

      .showcase-button-section {
        max-height: none;
        min-width: 400px;

        .button-container {
          gap: 20px;
          padding: 20px 0;

          .showcase-heading-section {
            font-size: 16px;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }

    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      max-height: unset;

      .showcase-text-section {
        padding: 20px;
        align-items: center;
        max-width: 400px;
        margin: 0 auto;
        text-align: center;

        &::after {
          display: block;
        }
      }

      .showcase-text-section,
      .showcase-heading-section,
      .showcase-button-section {
        flex: 1 0 100%;
      }

      .showcase-heading-section,
      .button-container {
        width: 100%;
        text-align: center;
      }

      .triangle-container {
        display: none;
      }
    }
  }

  // CSS rules when text section is not present
  &[data-showcase-text-section="false"] {
    .showcase-text-section {
      display: none;
    }

    .showcase-heading-section {
      font-size: 36px;
      flex: 0 1 50%;
    }

    @media screen and (max-width: 1300px) {
      .showcase-heading-section {
        font-size: 26px;
      }
    }

    @media screen and (max-width: 950px) {
      flex-wrap: wrap;
      max-height: unset;

      .showcase-text-section {
        font-size: 13px;
        padding: 20px;
        align-items: center;
        max-width: 400px;
        margin: 0 auto;
        text-align: center;

        &::after {
          display: block;
        }
      }

      .showcase-button-section {
        max-height: none;

        .button-container {
          gap: 20px;
          padding: 20px 0;

          .showcase-heading-section {
            padding: 0;
            font-size: 20px;
            justify-content: center;
            text-align: center;
          }
        }
      }

      .showcase-text-section,
      .showcase-heading-section,
      .showcase-button-section {
        flex: 1 0 100%;
      }

      .showcase-heading-section,
      .button-container {
        width: 100%;
        text-align: center;
      }

      .triangle-container {
        display: none;
      }
    }
  }
}
